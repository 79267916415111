<template>
  <div fluid>
    <!-- Table Top -->
    <b-card>
      <b-row>
        <b-col>
          <validation-observer
            v-slot="{ handleSubmit }"
          >
            <b-form
              ref="myForm"
              class="p-2"
              @submit.prevent="handleSubmit(addLive)"
            >
              <b-row>
                <b-col md="6">
                  <inpo-select
                    :inpo-id.sync="liveData.entity_id"
                  />
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Live Name"
                    rules="required"
                  >
                    <b-form-group
                      label="Live Name"
                      label-for="live_name"
                    >
                      <b-form-input
                        id="live_name"
                        v-model="liveData.activity_name"
                        placeholder="Live Name"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Live Description"
                    rules="required"
                  >
                    <b-form-group
                      label="Live Description"
                      label-for="live_des"
                    >
                      <b-form-input
                        id="live_des"
                        v-model="liveData.activity_description"
                        placeholder="Live Description"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Live Link"
                    rules="required|youtube-url"
                  >
                    <b-form-group
                      label="Live Link"
                      label-for="live_des"
                    >
                      <b-form-input
                        id="live_des"
                        v-model="liveData.session_link"
                        placeholder="Live Link"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Live Start"
                    rules="required"
                    :detect-input="false"
                  >
                    <b-form-group
                      label="Live Start"
                      label-for="live_des"
                    >
                      <flat-pickr
                        v-model="liveData.session_start"
                        class="form-control"
                        :config="{ enableTime: true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
                        @on-close="enableEndDate"
                        @input="validationContext.validate(liveData.session_start)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="Live End"
                    rules="required"
                    :detect-input="false"
                  >
                    <b-form-group
                      label="Live End"
                      label-for="live_des"
                    >
                      <flat-pickr
                        v-model="liveData.session_end"
                        class="form-control"
                        :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', minDate: liveData.session_start,clickOpens:isEndDateEnabled }"
                        @input="validationContext.validate(liveData.session_end)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="6">
                  <validation-provider
                    #default="validationContext"
                    name="To Be Raised"
                    rules="required"
                  >
                    <b-form-group
                      label="To Be Raised"
                      label-for="to_be_raised"
                    >
                      <b-form-input
                        id="to_be_raised"
                        v-model="liveData.session_to_be_raised"
                        type="number"
                        placeholder="To Be Raised"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col
                  cols="12"
                  class="mt-2"
                >
                  <b-button
                    variant="primary"
                    type="submit"
                  >
                    Add
                  </b-button>
                  <back />
                </b-col>

              </b-row>
            </b-form>
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'

import InpoSelect from '@/common/components/LiveManagement/InpoLinkedWithStripeSelect.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import handleFormData from '@/common/compositions/common/handleFormData'
import Back from '@/common/components/common/Back.vue'

export default {
  name: 'AddLive',
  components: {
    flatPickr,
    InpoSelect,
    Back,
  },
  setup() {
    const {
      getValidationState,
    } = formValidation()
    const { successfulOperationAlert, showErrors } = handleAlerts()
    const { setFormData, formData } = handleFormData()

    return {
      getValidationState,
      successfulOperationAlert,
      showErrors,
      setFormData,
      formData,
    }
  },
  data() {
    return {
      isEndDateEnabled: false,
      liveData: {
        activity_name: null,
        session_link: null,
        session_start: null,
        session_end: null,
        entity_id: null,
        activity_description: null,
        activity_type: null,
        activate_time: null,
        session_to_be_raised: null,
      },
    }
  },
  methods: {
    addLive() {
      this.setFormData(this.liveData)
      this.$activities.post('internalops/live', this.formData).then(() => {
        this.successfulOperationAlert('Live is added successfully')
        this.$router.push({ name: 'live-management-list' })
      }).catch(err => {
        this.showErrors(err.response.data.errors)
      })
    },
    enableEndDate() {
      this.isEndDateEnabled = true
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
